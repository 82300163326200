import {
  createUseAppBatchSwr,
  createUseAppListInfiniteSwr,
  createUseAppListItemsSwr,
  createUseAppListItemSwr,
  createUseAppListSwr,
  createUseAppSwr,
} from '@gain/api/swr'
import {
  AdvisorDealListItem,
  AssetListItem,
  Deal,
  GetDealParams,
  GetLegalEntityParams,
  LegalEntity,
} from '@gain/rpc/app-model'
import { ListFilter } from '@gain/rpc/list-model'
import {
  createListFilterForPastFiveYears,
  listFilter,
  listFilters,
  listSort,
} from '@gain/rpc/utils'

// These options avoid revalidating when data is stale.
const revalidateOnFocusOrReconnectOptions = {
  // The following settings are the SWR default; but we override them
  // globally hence the need to set them explicitly.
  revalidateOnMount: undefined,
  revalidateOnFocus: true,
  revalidateOnReconnect: true,

  // revalidateIfStale = false is already set globally in the SWR config, but
  // make it explicit here to avoid breaking if we do decide to change the
  // global config.
  revalidateIfStale: false,
}

// This disables all revalidations on SWR and is the same as using the swr
// immutable hook:
// https://swr.vercel.app/docs/revalidation#disable-automatic-revalidations
const immutableOptions = {
  revalidateOnMount: undefined,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  revalidateIfStale: false,
}

// After the initial load this revalidates once per day. This is useful
// for data that is not expected to change very often.
const revalidateDailyOptions = {
  ...immutableOptions,
  refreshInterval: 1000 * 60 * 60 * 24, // 24 hours
}

export const useArticle = createUseAppSwr('data.getArticle')
export const useArticleList = createUseAppListSwr('data.listArticles')
export const useArticleListInfinite = createUseAppListInfiniteSwr('data.listArticles')

export const useAsset = createUseAppSwr('data.getAsset')
export const useAssetList = createUseAppListSwr('data.listAssets')
export const useAssetListItem = createUseAppListItemSwr('data.listAssets', 'id')
export const useAssetListItems = createUseAppListItemsSwr('data.listAssets', 'id')
export function useHasAssetListResults(filters: ListFilter<AssetListItem>[]) {
  const swrAssets = useAssetList(() => {
    if (!filters.length) {
      return null
    }

    return {
      filter: filters,
      limit: 0,
    }
  })

  return swrAssets.data.counts.filtered > 0
}

export const useAssetOwnershipDistribution = createUseAppSwr('data.getAssetOwnershipDistribution')

/**
 * Fetches a list of assets similar to the given asset ID. This request is cached
 * and not revalidated unless the user refreshes the page. This is because the
 * similarity of assets is not expected to change frequently and the search is
 * computationally expensive.
 */
export function useSimilarAssets(assetId: number, limit: number) {
  return useAssetList(
    {
      filter: listFilters(listFilter('relevanceRank', '=', assetId)),
      sort: [listSort('relevanceRank', 'asc')],
      limit,
    },
    revalidateDailyOptions
  )
}

export const useRelatedAssets = createUseAppSwr('data.getRelatedAssets')
export const useGlobalUltimateOwner = createUseAppSwr('data.getGlobalUltimateOwner')
export const useAssetRelevantIndustries = createUseAppSwr('data.getAssetRelevantIndustries')

export const usePersonList = createUseAppListSwr('data.listPersons')

export const useConferenceEditionListItem = createUseAppListItemSwr(
  'data.listConferenceEditions',
  'id'
)

// Because bookmark lists are updated directly in SWR cache avoid excessive revalidations
// when bookmark buttons are mounted.
export const useCustomAssetLists = createUseAppSwr(
  'lists.getCustomAssetLists',
  revalidateOnFocusOrReconnectOptions
)
export const useCustomAssetListRatings = createUseAppSwr('lists.getListRatings')
export const useCustomAssetListUpdates = createUseAppSwr('lists.listUpdates')
export const useCustomAssetListUrlImportTaskStatus = createUseAppSwr(
  'lists.getCustomAssetListUrlImportTaskState',
  { paramTransformer: (id: number) => ({ id }) }
)

// The backend returns a generic type that we override to BookmarkFilteredLists. I.e. the type of
// filters and available operators are typed only the frontend, not the backend.
export const useBookmarkFilteredLists = createUseAppListSwr('lists.listCustomAssetQueries')
export const useDealsPerTypeByYear = createUseAppSwr('lists.getDealsPerTypeByYear')

export const useDeal = createUseAppSwr('data.getDeal', {
  paramTransformer: (id: number) => ({ id }),
})
export const useDealList = createUseAppListSwr('data.listDeals')
export const useDeals = createUseAppBatchSwr<Deal, number, GetDealParams>(
  'data.getDeal',
  (input) => ({ id: input })
)
export const useCurrencyList = createUseAppListSwr('data.listCurrencies', revalidateDailyOptions)
export const useIndustrySegmentDealsByYear = createUseAppSwr('data.getIndustrySegmentDealsByYear')
export const useActiveInvestors = createUseAppSwr('data.getActiveInvestors')
export const useActiveStrategicAcquirers = createUseAppSwr('data.getActiveStrategicAcquirers')
export const useActiveConsolidators = createUseAppSwr('data.getActiveConsolidators')
export const useIndustry = createUseAppSwr('data.getIndustry')
export const useIndustryList = createUseAppListSwr('data.listIndustries')
export const useIndustryListItem = createUseAppListItemSwr('data.listIndustries', 'id')

export const useIndustryMarketSegment = createUseAppSwr('data.getIndustryMarketSegmentById')
export const useIndustryMarketSegmentList = createUseAppListSwr('data.listIndustryMarketSegments')

export const useInvestor = createUseAppSwr('data.getInvestor')
export const useInvestorStrategy = createUseAppSwr('data.getInvestorStrategy')
export const useInvestorProfileStrategies = createUseAppSwr('data.getInvestorProfileStrategies', {
  paramTransformer: (input: number) => ({ investorId: input }),
})
export const useInvestorDealsPerTypeByYear = createUseAppSwr('data.getInvestorDealsPerTypeByYear')
export const useInvestorList = createUseAppListSwr('data.listInvestors')
export const useInvestorListItem = createUseAppListItemSwr('data.listInvestors', 'id')
export const useInvestorListItems = createUseAppListItemsSwr('data.listInvestors', 'id')

export const useInvestorStrategyList = createUseAppListSwr('data.listInvestorStrategies')
export const useInvestorStrategyListItem = createUseAppListItemSwr(
  'data.listInvestorStrategies',
  'strategyId'
)

export const useLatestFeature = createUseAppSwr('data.getLatestFeature')

export const useLegalEntity = createUseAppSwr('data.getLegalEntity', {
  paramTransformer: (id: number) => ({ id }),
})
export const useLegalEntities = createUseAppBatchSwr<LegalEntity, number, GetLegalEntityParams>(
  'data.getLegalEntity',
  (input) => ({ id: input })
)
export const useAdvisor = createUseAppSwr('data.getAdvisor')
export const useAdvisorListItem = createUseAppListItemSwr('data.listAdvisors', 'id')
export const useAssetAdvisors = createUseAppSwr('data.getAssetAdvisors', {
  paramTransformer: (id: number) => ({ id }),
})
export const useAssetConferenceEditions = createUseAppSwr('data.getAssetConferenceEditions', {
  paramTransformer: (id: number) => ({ id }),
})
export const useRelatedDealAdvisors = createUseAppSwr('data.getRelatedDealAdvisors')
export const useAdvisorInvestorClients = createUseAppSwr('data.getAdvisorInvestorClients')
export const useAdvisorAssetClients = createUseAppSwr('data.getAdvisorAssetClients')
export const useAdvisorDealsList = createUseAppListSwr('data.listAdvisorDeals')

export function useAdvisorDealsLastFiveYearsList(advisorId?: number) {
  return useAdvisorDealsList(() => {
    if (!advisorId) {
      return null
    }

    return {
      limit: 500,
      filter: listFilters<AdvisorDealListItem>(
        listFilter('advisorId', '=', advisorId),
        createListFilterForPastFiveYears('announcementDate')
      ),
      sort: [{ field: 'announcementDate', direction: 'desc' }],
    }
  })
}

export const useAdvisorListItems = createUseAppListItemsSwr('data.listAdvisors', 'id')
export const useSearch = createUseAppSwr('data.search')
export const useSectorList = createUseAppListSwr('data.listSectors', revalidateDailyOptions)
export const useSubsectorList = createUseAppListSwr('data.listSubsectors', revalidateDailyOptions)

export const useTagList = createUseAppListSwr('data.listTags')
export const useAssociatedTagList = createUseAppSwr('data.listAssociatedTags')
export const useSuggestedTagList = createUseAppSwr('data.suggestTags')

export const useUserInvitationLink = createUseAppSwr('admin.getUserInvitationLink')

export const useGetGeometryForPlaceId = createUseAppSwr('data.getGeometryForPlaceId')

/**
 * @deprecated Not really deprecated but make sure you need the SWR version while you can also
 * get it from the context with `useUserProfileContext`
 */
export const useUserProfile = createUseAppSwr('account.getUserProfile', {
  revalidateOnFocus: true,
})
export const useTrackVisitApi = createUseAppSwr('account.trackVisit')
export const useListRecentVisits = createUseAppSwr('account.listRecentVisits')

export const useUserList = createUseAppListSwr('admin.listUsers')

export const useListedSecurityList = createUseAppListSwr('data.listListedSecurities')
export const useListedSecurityChartData = createUseAppSwr(
  'data.getListedSecurityChartData',
  revalidateDailyOptions
)
export const useListedSecurityValuation = createUseAppSwr('data.getListedSecurityValuation')
export const useListedSecurityValuations = createUseAppSwr('data.getListedSecurityValuations', {
  paramTransformer: (listedSecurityId: number) => ({
    listedSecurityId,
  }),
})
